<template>
  <div class="w-100 d-lg-flex align-items-center justify-content-center size-image">
    <b-img
      fluid
      :src="imgLogo"
      alt="logo ecoopsos"
      width="550"
    />
  </div>
</template>
<script>
import { 
  BImg, 
} from 'bootstrap-vue'
export default {
  components: {
    BImg,
  },
  data() {
    return {
      imgLogo: require('@/assets/images/logo/ecoopsos-logo.png'),
    }
  },
}
</script>
